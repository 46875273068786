import React, {Component} from 'react';

class EmailSignaturePreview extends Component {

    constructor(props) {
        super(props);

        this.iframe = React.createRef();
    }

    componentDidMount() {
        this.renderIframe();
    }

    componentDidUpdate() {
        this.renderIframe();
    }

    renderIframe() {

        const content = `
    <html><head><title>Email Signature</title>

    <meta content="text/html; charset=utf-8" http-equiv="Content-Type">

    </head>
    <body style="font-family:'helvetica neue',helvetica,sans-serif;margin:0;padding:0;" >
        <table>
        <tbody>
          <tr>
           <td style="padding:0;font-family:'helvetica neue',helvetica,sans-serif;height:20px; vertical-align:top; text-align:left;" valign="top" align="left" >
            <span style="font-family:'helvetica neue',helvetica,sans-serif;font-size:14pt; font-weight:bold;color:#6528F7;font-size:11pt;letter-spacing:1px;text-transform:uppercase">${this.props.name}<span><br></span></span>
           </td>
          </tr>
          <tr>
           <td style="padding:0;font-family:'helvetica neue',helvetica,sans-serif;height:20px; vertical-align:top; text-align:left;" valign="top" align="left">
            <span style="font-family:'helvetica neue',helvetica,sans-serif;font-size:8pt;font-weight:800;color:#000;;letter-spacing:1px;text-transform:uppercase;">${this.props.jobTitle}<span><br></span></span>
           </td>
          </tr>
          <tr style="">
           <td style="height:30px ; font-family:'helvetica neue',helvetica,sans-serif;vertical-align:top;padding-top:10px" valign="top" align="left">
            <span style="font-family:'helvetica neue',helvetica,sans-serif;font-size:8pt;font-weight:800;color:#000;letter-spacing:1px;text-transform:uppercase">LPF Academy, 224/A Quarry Road, Dehiwela<span><br></span></span>
           </td>
          </tr>
          <tr>
           <td style="padding-top: 20px;border-top:2px solid #000000;font-family:'helvetica neue',helvetica,sans-serif;height:20px; vertical-align:middle; text-align:left;padding-top:6px" valign="middle" align="left">
            <span style="font-family:'helvetica neue',helvetica,sans-serif;font-size:8pt;color:#000;letter-spacing:1px;line-height: 14px"><span style="color:#6528F7">Tel: </span> +94 112719715   <span><br></span></span>
            <span style="font-family:'helvetica neue',helvetica,sans-serif;font-size:8pt;color:#000;letter-spacing:1px;line-height: 14px"><span style="color:#6528F7">Mob: </span> +94 ${this.props.mobileNumber} <span><br></span></span>
            <span style="font-family:'helvetica neue',helvetica,sans-serif;font-size:8pt;color:#000;letter-spacing:1px;line-height: 14px"><span style="color:#6528F7">Email: </span> ${this.props.email} <span><br></span></span>
            <span style="font-family:'helvetica neue',helvetica,sans-serif;font-size:8pt;color:#000;letter-spacing:1px;line-height: 14px"><span style="color:#6528F7">Web:</span> www.lpfschools.com <span><br></span></span>
           </td>
          <tr>
     
          </tr>
         </tbody>
</table>
        </body>
    </html>
    `;

        const doc = this.iframe.current.contentDocument || this.iframe.current.contentWindow.document;

        doc.write(content);
        doc.close();

    }

    render() {
        return <iframe title={'signature-frame'} ref={this.iframe} style={{width:'100%'}} height="150"/>;
    }
}

export default EmailSignaturePreview
