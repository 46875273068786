import React, {Component} from 'react';
import "./EmailSignatureGenerator.css"
import EmailSignaturePreview from "./EmailSignaturePreview";

class EmailSignatureGenerator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            mobileNumber: "",
            jobTitle: "",
            email: "",
            copied: false,
            isEmailValid: false,
            isJobValid: false,
            isMobileValid: false,
            isNameValid: false
        };

        this.iframe = React.createRef();
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleMobile = this.handleMobile.bind(this);
        this.handleEmail = this.handleEmail.bind(this)
        this.handleClickCopy = this.handleClickCopy.bind(this);
    }

    handleChangeName(event) {
        this.setState({name: event.target.value});
        if(event.target.value.trim().length === 0){
            this.setState({isNameValid: false});
        }
        else{

            this.setState({isNameValid: true});
        }
        this.setState({copied: false});
    }

    handleChangeTitle(event) {
        this.setState({jobTitle: event.target.value});
        if(event.target.value.trim().length === 0){
            this.setState({isJobValid: false});
        }
        else{

            this.setState({isJobValid: true});
        }
        this.setState({copied: false});
    }

    handleMobile(event) {
        this.setState({mobileNumber: this.modifyPhoneNumber(event.target.value)});
        this.setState({copied: false});
        if(event.target.value.trim().length === 0){
            this.setState({isMobileValid : false})
        }
        else{
            const isValidMobileNumber = this.validateMobileNumber(event.target.value);
            this.setState({isMobileValid: isValidMobileNumber});
        }

    }

    modifyPhoneNumber(number){
        if (number.length === 10) {
            // Remove the first character if the length is 10
            return number.substring(1);
        } else if (number.startsWith('+94')) {
            // Remove the "+94" prefix if it exists
            return number.substring(3);
        } else {
            return number; // Return the original number if no condition is met
        }
    }
    handleEmail(event) {
        this.setState({email: event.target.value})
        this.setState({copied: false});
        if(event.target.value.trim().length === 0){
            this.setState({isEmailValid : false})
        }
        else{
            const isValidEmail = this.validateEmail(event.target.value);
            this.setState({isEmailValid: isValidEmail});
        }

    }

    validateEmail = (email) => {
        // Regular expression pattern for validating email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    validateMobileNumber = (mobileNumber) => {
        // Regular expression pattern for validating mobile number starting with '07' followed by 8 digits
        const mobileNumberPattern = /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|5|6|7|8)\d)\d{6}$/;
        return mobileNumberPattern.test(mobileNumber);
    };

    getIframe() {
        return this.iframe.current.iframe.current.contentDocument;
    }

    handleChange = (e) => {
        this.setState({
            email: e.target.value,
            isValid: true, // Reset validation on input change
        });
    };

    handleClickCopy(event) {
        event.preventDefault();

        const doc = this.getIframe();

        console.log(doc);

        doc.execCommand("selectAll");
        doc.execCommand("copy");

        this.setState({copied: true});
    }

    render() {
        const {copied} = this.state;

        return (
            <div className="email-sign-container">
                <div className={`email-sign-form-container`}>
                    <div className="line-one"></div>
                    <h3 className={`py-4`}>Signature Details</h3>
                    <p className="App-intro">
                        <div className="mb-3">
                            <label>Enter your Name</label>
                            <input value={this.state.name} className={'form-control email-sign-input'}
                                   onChange={this.handleChangeName}/>
                        </div>
                        <div className="mb-3">
                            <label>Enter your Job Title</label>
                            <input
                                className={'form-control email-sign-input'}
                                value={this.state.jobTitle}
                                onChange={this.handleChangeTitle}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Enter your Mobile Number</label>
                            <input
                                className={'form-control email-sign-input'}
                                value={this.state.mobileNumber}
                                onChange={this.handleMobile}
                                style={{border: this.state.isMobileValid ? '1px solid black' : '1px solid red'}}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Enter your Official Email</label>
                            <input
                                className={'form-control email-sign-input'}
                                value={this.state.email}
                                onChange={this.handleEmail}
                                style={{border: this.state.isEmailValid ? '1px solid black' : '1px solid red'}}
                            />
                        </div>
                        <button
                            className="btn btn-copy mt-4"
                            onClick={this.handleClickCopy}
                            onBlur={this.handleBlurCopy}
                            disabled={!(this.state.isEmailValid && this.state.isMobileValid && this.state.isJobValid && this.state.isNameValid) ? "disabled" : ""}
                        >
                            {copied ? "Copied!" : "Copy"}
                        </button>
                    </p>
                </div>

                <div className="signature-container p-md-4">
                    <div>
                        <div className={`signature-preview-container`}>
                            <EmailSignaturePreview
                                name={this.state.name}
                                mobileNumber={this.state.mobileNumber}
                                jobTitle={this.state.jobTitle}
                                email={this.state.email}
                                ref={this.iframe}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default EmailSignatureGenerator;
