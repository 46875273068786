import './App.css';
import EmailSignatureGenerator from "./component/EmailSignatureGenerator";
import "bootstrap/dist/css/bootstrap.css"

function App() {
  return <div>
    <div className={`main-header`}>Email Signature Generator</div>
    <div className={`page-container`}>
      <EmailSignatureGenerator/>
    </div>
    <div className={'main-footer'}>
      Design & Developed by Nadun Dhananjaya
    </div>
  </div>
}

export default App;
